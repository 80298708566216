import 'imports-loader?type=commonjs&wrapper=window!../vendors/wow.min.js';
import '../vendors/owl.carousel.min.js';
import '../vendors/pushy.min.js';
window.addEventListener('load', () => {
  $('.owl-carousel').on('initialized.owl.carousel', function (event) {
    $(this).find('.owl-item').each(function () {
      $(this).removeClass('first-active');
    });
    $(this).find('.owl-item.active').addClass('first-active');
    $(this).find('.owl-item.active').last().removeClass('first-active');
  });
  $('ul.products-tabs-specials-container-header').each(function (i) {
    var storage = localStorage.getItem('tab' + i);

    if (storage) {
      $(this).find('li').removeClass('products-tabs-specials-container-header-item-active').eq(storage).addClass('products-tabs-specials-container-header-item-active').closest('div.products-tabs-specials-container').find('div.tab-content').removeClass('active').eq(storage).addClass('active');
    }
  });
  $('ul.products-tabs-specials-container-header').on('click', 'li:not(.active)', function () {
    $(this).addClass('products-tabs-specials-container-header-item-active').siblings().removeClass('products-tabs-specials-container-header-item-active').closest('div.products-tabs-specials-container').find('div.tab-content').removeClass('active').eq($(this).index()).addClass('active');
    var ulIndex = $('ul.products-tabs-specials-container-header').index($(this).parents('ul.products-tabs-specials-container-header'));
    localStorage.removeItem('tab' + ulIndex);
    localStorage.setItem('tab' + ulIndex, $(this).index());
  });
  const wowObj = new WOW();
  wowObj.init();
  $('.owl-carousel-home-general').owlCarousel({
    items: 3,
    nav: true,
    slideSpeed: 300,
    dots: true,
    rtl: false,
    margin: 0,
    touchDrag: true,
    paginationSpeed: 400
  });
  $('.owl-carousel-productList').owlCarousel({
    items: 4,
    nav: true,
    slideSpeed: 300,
    dots: true,
    rtl: false,
    margin: 0,
    touchDrag: true,
    paginationSpeed: 400
  });
  $('.owl-carousel').on('changed.owl.carousel', function (event) {
    $(this).find('.owl-item').each(function () {
      $(this).removeClass('first-active');
    });
    $(this).find('.owl-item.active').addClass('first-active');
    $(this).find('.owl-item.active').last().removeClass('first-active');
  });
  $('.owl-carousel').each(function () {
    var $this = $(this);
    $(this).find('.owl-item.active').last().addClass('last-active');
    $(".owl-carousel").on('initialized.owl.carousel translated.owl.carousel', function () {
      var $this = $(this);
      $this.find('.owl-item.last-active').each(function () {
        $(this).removeClass('last-active');
      });
      $(this).find('.owl-item.active').last().addClass('last-active');
    });
  });
  $('.show-all-cat-dropdown').each(function () {
    if ($(this).siblings('.product-menu-categories-hidden').length > 0) {
      var $childIndicator = $('<span class="child-indicator"><i class="fa fa-angle-right"></i></span>');
      $(this).siblings('.product-menu-categories-hidden').hide();

      if ($(this).siblings('.product-menu-categories-hidden').is(':visible')) {
        $childIndicator.addClass('open');
        $childIndicator.html('<i class="fa fa-angle-up"></i>');
      }

      $(this).on('click', function () {
        $(this).siblings('.product-menu-categories-hidden').toggle('fast', function () {
          if ($(this).is(':visible')) {
            $childIndicator.addClass('open');
            $childIndicator.html('<i class="fa fa-angle-up"></i>');
          } else {
            $childIndicator.removeClass('open');
            $childIndicator.html('<i class="fa fa-angle-right"></i>');
          }
        });
        return false;
      });
      $(this).append($childIndicator);
    }
  });
  $('.site-menu-row .menu_fast_toggle .menu-dropdown-list .menu-dropdown-item .menu-dropdown-sub').hide();
  var menuItemAnimationSub;
  $('.site-menu-row .menu-dropdown-list .menu-dropdown-item').hover(function () {
    if (menuItemAnimationSub != null) {
      menuItemAnimationSub.stop(true, true);
    }

    menuItemAnimationSub = $(this).children('.menu-dropdown-sub');
    menuItemAnimationSub.toggle('fast', function () {
      menuItemAnimationSub = null;
    });
  });
  $('.site-menu-row .product-menu-categories .menu-dropdown .menu-dropdown-item .categoryidbymenuhidden').each(function () {
    var currentmenucategoryid = $(this).text();
    var currentproductcategoryid = $(".categoryidbyproducthidden").text();
    var currentcategoryidcatalog = $(".categoryidcataloghidden").text();

    if (currentmenucategoryid == currentproductcategoryid) {
      $(this).parent('.menu-dropdown-link-text').css("font-family", "OpenSansBold");
    }

    if (currentmenucategoryid == currentcategoryidcatalog) {
      $(this).parent('.menu-dropdown-link-text').css("font-family", "OpenSansBold");
    }
  });
  $('.scroll-to-top a').click(function () {
    var scroll_el = $(this).attr('href');

    if ($(scroll_el).length != 0) {
      $('html, body').animate({
        scrollTop: $(scroll_el).offset().top
      }, 500); // анимируем скроолинг к элементу scroll_el
    }

    return false; // выключаем стандартное действие
  });
  $('.site-body').append($('.site-head-cart .pushy-right'));
  $('.site-body').append($('.site-head-cart .site-overlay'));
});