import AppDependency from '../../../scripts/appDependency.js';
import '../styles/general.scss';
import '../styles/partials/toolbar-top.scss';
import '../styles/partials/menu-dropdown.scss';
import '../styles/partials/header.scss';
import '../styles/font-electro.css';
import '../styles/font-awesome.css';
import '../styles/owl.carousel.css';
import '../styles/partials/gallery.scss';
import '../styles/views/product.scss';
import '../scripts/_partials/product-view/styles/product-view.scss';
import '../styles/pushy.css';
import '../styles/animate.css';
import '../styles/partials/footer.scss';
import '../styles/partials/brands-carousel.scss';
import '../styles/common/links.scss';
import '../styles/common/social.scss';
import '../scripts/_partials/cart/styles/cart.scss';
import '../scripts/_common/modal/styles/modal.scss';
import '../scripts/_common/harmonica/styles/harmonica.scss';
import '../scripts/_partials/colors-viewer/styles/colors-viewer.scss';
import '../styles/partials/products-specials.scss';
import '../vendors/new.js';
import '../scripts/_partials/cart/cart.module.js';
import '../scripts/_partials/product-view/productView.module.js';
import '../scripts/checkout/checkout.module.js'; //import '../scripts/product/productQuickview.module.js';

import ProductCustom from '../scripts/productCustom/product.module.js';
AppDependency.addItem(ProductCustom);
import '../scripts/product/product.module.js';
import subscribeModule from '../../../scripts/_partials/subscribe/subscribe.module.js';
AppDependency.addItem(subscribeModule);